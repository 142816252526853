<template>
  <div class="searchTop">
    <van-sticky class="search-sticky">
      <van-row type="flex" align="center" class="search-top-row">
        <van-col class="tabItem" @click="changeType(-1)">
          <p
            :class="[
              'tabItemTotal',
              order !== 1 && order !== 2 ? 'itemTopAction' : '',
            ]"
          >
            {{ $t.searchInfo.general }}
          </p>
        </van-col>
        <van-col class="tabItem" @click="changeType(1)">
          <p :class="['tabItemTotal', order === 1 ? 'itemTopAction' : '']">
            {{ $t.searchInfo.sales }}
          </p>
        </van-col>
        <van-col class="tabItem" @click="changeType(2)">
          <p :class="['tabItemTotal', order === 2 ? 'itemTopAction' : '']">
            {{ $t.searchInfo.forwards }}
          </p>
        </van-col>
        <van-col class="tabItem" @click="changeType(3)">
          <p :class="['tabItemTotal', isFilter ? 'itemTopAction' : '']">
            {{ $t.meta.filter }}
          </p>
        </van-col>
      </van-row>
    </van-sticky>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col } from "vant";
Vue.use(Sticky).use(Row).use(Col);
export default {
  name: "SearchTop",
  props: ["order", "isFilter"],
  data() {
    return {};
  },
  methods: {
    //切换排序
    changeType(order) {
      this.$emit("changeOrder", order);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.searchTop {
  width: 100%;
  font-size: 26px;
  .search-sticky {
    background-color: #ffffff;
    ::v-deep .van-sticky {
      background-color: #ffffff;
    }
    .search-top-row {
      width: 100%;
      text-align: center;
      background-color: #ffffff;
      padding-top: 15px;
      padding-bottom: 15px;
      transition: padding-top 0.1s;
      .tabItem {
        width: 25%;
        text-align: center;
        font-size: 14px;
        color: #a7a7a7;
        .tabItemTotal {
          margin: 0;
          padding: 0;
        }
        .itemTop {
          @include publicFlex;
          text-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          .itemTopName {
            margin: 0;
            padding: 0;
          }
        }
        .itemTopAction {
          color: #01aaa3;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
