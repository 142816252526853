<template>
  <div class="search">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <search-top @changeOrder="changeOrder" :order="order" :isFilter="checkFilter()"></search-top>
      </van-sticky>
      <lists :productList="productList" ref="search"></lists>
    </van-list>

    <van-popup
      v-model="showFilter"
      position="bottom"
      :style="{ height: '80%', width: '100%' }"
    >
      <div class="popup-header">{{ $t.meta.allFilter }}</div>
      <van-form>
        <van-field name="switch" :label="$t.meta.isCouponProduct">
          <template #input>
            <van-switch v-model="isCouponProduct" size="20" /></template
        ></van-field>
        <van-field name="switch" :label="$t.meta.isBrandProduct">
          <template #input>
            <van-switch v-model="isBrandProduct" size="20" /></template
        ></van-field>
        <van-field name="switch" :label="$t.meta.isAttestationProduct">
          <template #input>
            <van-switch v-model="isAttestationProduct" size="20" /></template
        ></van-field>

        <van-field
          readonly
          clickable
          name="picker"
          :value="attname"
          :label="$t.meta.attType"
          :placeholder="$t.meta.cSelect"
          @click="showPicker = true"
        />
      </van-form>

      <div class="popup-footer">
        <van-button
          class="reset"
          type="info"
          block
          square
          @click="filterReset"
          >{{ $t.resetText }}</van-button
        >

        <van-button
          class="confirm"
          type="primary"
          block
          square
          @click="filterSubmit"
          >{{ $t.okText }}</van-button
        >
      </div>
    </van-popup>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="attestationTypeList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import Lists from "@main/common/Lists";
import SearchTop from "./components/SearchTop";

import Vue from "vue";
import {
  Sticky,
  List,
  Image,
  Popup,
  Form,
  Field,
  Switch,
  Button,
  Picker,
} from "vant";
Vue.use(List)
  .use(Sticky)
  .use(Image)
  .use(Popup)
  .use(Form)
  .use(Field)
  .use(Switch)
  .use(Button)
  .use(Picker);

export default {
  name: "Search",
  components: {
    Lists,
    SearchTop,
  },
  data() {
    return {
      //首页分类
      sreachValues: "",
      classId: "",
      className: "",
      //认证分类列表
      attestationTypeList: [],
      productList: [],
      pageIndex: 1,
      order: "", //排序
      loading: true,
      finished: false,
      showFilter: false,
      showPicker: false,
      isCouponProduct: false,
      isBrandProduct: false,
      isAttestationProduct: false,
      atid: null,
      attname: "",
    };
  },
  created() {
    const { ClassId, ClassName, SreachValues, Order, IsCouponProduct } =
      this.$route.query;
    this.classId = ClassId;
    this.className = ClassName;
    this.className && (document.title = this.className);
    this.sreachValues = SreachValues;
    this.order = Order;
    this.isCouponProduct = IsCouponProduct;
    this.getSearchProductByPage();
    this.getAttestationTypeList();
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName("search-top-row")[0].style.paddingTop =
          "calc(15px + env(safe-area-inset-top))";
      } else {
        document.getElementsByClassName("search-top-row")[0].style.paddingTop =
          "15px";
      }
    });
  },
  methods: {
    onConfirm(option) {
      this.attname = option.text;
      this.atid = option.value;
      this.showPicker = false;
    },
    checkFilter() {
      if (
        this.isCouponProduct ||
        this.isBrandProduct ||
        this.isAttestationProduct ||
        this.atid
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterReset() {
      this.isCouponProduct = false;
      this.isBrandProduct = false;
      this.isAttestationProduct = false;
      this.attname = "";
      this.atid = null;
    },
    filterSubmit() {
      this.showFilter = false;
      this.changeOrder(this.order);
    },
    getAttestationTypeList() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      this.$api.product
        .getAttestationTypeList(param)
        .then((res) => {
          const data = res.data;
          this.formatData(data);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    formatData(items) {
      items.forEach((item) => {
        let column = {
          text: item.AttestationName,
          value: item.ATId,
        };
        this.attestationTypeList.push(column);
      });
    },
    // 获取搜索页数据
    getSearchProductByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        Order: this.order,
        ScreenValues: this.classId,
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex,
        IsCouponProduct: this.isCouponProduct ? 1 : 0,
        IsBrandProduct: this.isBrandProduct ? 1 : 0,
        IsAttestationProduct: this.isAttestationProduct ? 1 : 0,
        ATId: this.atid,
      };
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          this.productList = data;
          if (
            this.productList === null ||
            this.productList === "" ||
            this.productList.length === 0
          ) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 筛选
    changeOrder(type) {
      if (type == 3) {
        this.showFilter = true;
        return;
      }
      window.scrollTo({
        top: 0,
      });
      this.order = type;
      this.pageIndex = 1;
      this.loading = true;
      this.finished = false;
      this.getSearchProductByPage();
    },
    //加载更多
    onLoad() {
      let param = {
        Order: this.order,
        ScreenValues: this.classId,
        SreachValues: this.sreachValues,
        PageIndex: this.pageIndex + 1,
        IsCouponProduct: this.isCouponProduct ? 1 : 0,
        IsBrandProduct: this.isBrandProduct ? 1 : 0,
        IsAttestationProduct: this.isAttestationProduct ? 1 : 0,
        ATId: this.atid,
      };
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.productList = this.productList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/var";
.search {
  width: 100%;
}
.popup-header {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
}
.van-form {
  width: 90%;
  margin: auto;
  .van-switch--on {
    background-color: #01aaa3;
  }
}
.popup-footer {
  position: fixed;
  bottom: calc(0px + env(safe-area-inset-bottom));
  width: 100%;
  text-align: center;
  .reset {
    width: 50%;
    display: inline-block;
    color: #01aaa3;
    background: #ffffff;
    border: none;
  }
  .confirm {
    width: 50%;
    display: inline-block;
    color: #ffffff;
    background: #01aaa3;
    border: none;
  }
}
</style>
